import { useCart } from "../../../contexts/cart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronBackIcon } from "../../../assets";
import { getImageUrl } from "../../../lib/utils";

const PoductDetails = () => {
  const { quantities, addToCart, removeFromCart } = useCart();
  let { name } = useParams();
  const navigate = useNavigate();
  const { state: product } = useLocation();

  const handleBack = () => {
		navigate(-1);
  };

  return (
		<>
			<div className="w-full flex flex-col justify-center items-center gap-5 px-3 pt-[2rem] py-8 font-apple-system">
				<div className="flex flex-row justify-between items-center w-full">
					<button onClick={handleBack}>
						<ChevronBackIcon
							width={"31"}
							height={"31"}
							color={"#EA542B"}
						/>
					</button>
					<span className="font-bold text-base lg:text-4xl  text-customOrange uppercase absolute left-1/2 transform -translate-x-1/2">
						{name}
					</span>
				</div>
				<div className="px-2 w-full grid grid-cols-1 lg:grid-cols-2 items-center justify-center ">
					<div>
						<img
							src={getImageUrl(product?.imageUrl)}
							alt="Sunset in the mountains"
							className="w-full pt-4 object-contain max-h-96"
						/>
					</div>
					<div>
						<div className="font-bold text-xl lg:text-4xl py-4 text-customOrange w-full text-center">
							{product?.name}
						</div>
						<div className="font-bold text-xl  text-black w-full py-2 text-center">
							Livraison rapide en top chrono sur
							Dakar !
						</div>
						<div className="h-[30%] pt-10">
							{product?.description}
						</div>
						<div className="sm:flex lg:flex-row sm:px-6 flex flex-col w-full border-t-2 border-black border-opacity-5 justify-between mt-3 pt-3">
							<div className="flex flex-col px-2 ">
								<span className="font-bold text-base lg!text-xl  text-[#F7A48E]  ">
									{product?.price} CFA
								</span>
							</div>
							{quantities[product?.id] ? (
								<div className="flex bg-[#EA542B] text-center items-center h-[2.5rem] mt-3">
									<button
										onClick={() =>
											removeFromCart(
												product?.id
											)
										}
										className=" w-10 h-10"
									>
										<span className="text-sm lg:text-4xl text-white font-bold">
											-
										</span>
									</button>
									<span className="w-full h-text-sm lg:text-xl text-white font-bold">
										{
											quantities[
												product
													?.id
											]
										}
									</span>
									<button
										onClick={() =>
											addToCart(
												product?.id
											)
										}
										className=" w-10 h-10"
									>
										<span className="text-sm lg:text-4xl text-white font-bold">
											+
										</span>
									</button>
								</div>
							) : (
								<button
									onClick={() =>
										addToCart(
											product?.id
										)
									}
									className="bg-[#EA542B] w-full lg:w-[10rem] h-10 mt-3"
								>
									<span className="w-full h-text-sm lg:text-base text-white font-bold">
										Ajouter +
									</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
  );
};

export default PoductDetails;
