import React, { createContext, ReactNode, useContext, useState } from "react";

type Product = {
  id: string;
  image: string;
  name: string;
  price: number;
  quantity: number;
};

type OrderInfo = {
  name: string;
  phoneNumber: string;
  address: string;
  validatedProducts: Product[];
  totalPrice: number;
};

type CartContextType = {
  quantities: { [key: string]: number };
  orderInfo: OrderInfo | null;
  addToCart: (id: string) => void;
  removeFromCart: (id: string) => void;
  getTotalQuantity: () => number;
  setOrderInfo: (info: OrderInfo) => void;
  clearCart: () => void;
};

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const [orderInfo, setOrderInfo] = useState<OrderInfo | null>(null);

  const addToCart = (id: string) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: (prevQuantities[id] || 0) + 1,
    }));
  };

  const removeFromCart = (id: string) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      if (newQuantities[id] > 1) {
        newQuantities[id]--;
      } else {
        delete newQuantities[id];
      }
      return newQuantities;
    });
  };

  const getTotalQuantity = () => {
    return Object.values(quantities).reduce(
      (total, quantity) => total + quantity,
      0
    );
  };

  const clearCart = () => {
    setQuantities({});
    setOrderInfo(null);
  };

  return (
    <CartContext.Provider
      value={{
        quantities,
        addToCart,
        removeFromCart,
        getTotalQuantity,
        orderInfo,
        setOrderInfo,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
