import React from "react";

const Livraison = () => {
  return (
    <div className="bg-white container mx-auto text-left p-8">
      <h1 className="text-3xl font-bold mb-6">Livraison</h1>

      <section className="mb-8">
        <h2 className="text-lg font-bold text-dark mb-4">
          Expédition & Modes de Livraison
        </h2>
        <p className="mb-4 text-gray-700">
          Chaque commande est traitée instantanément par notre logistique. Pour
          expédier vos commandes, les produits seront envoyés à l’adresse de
          livraison indiquée par l’internaute au cours du processus de commande.
          Il est rappelé que www.happychiill.sn ne livrera que sur la ville de
          Dakar (aucune livraison en banlieue de Dakar ou en province ne pourra
          être honorée).
        </p>
        <p className="mb-4 text-gray-700">
          L’internaute est responsable de l’exactitude des données qu’il
          communique via son site internet concernant son adresse de livraison
          ainsi que de ses informations personnelles (n° de téléphone compris).
          www.happychiill.sn ne pourra en aucun cas être rendu responsable dans
          le cas d’une impossibilité de délivrance du colis due à une
          imprécision ou inexactitude de l’adresse de livraison, ou du numéro de
          téléphone. La livraison est assurée par nos services. Le délai estimé
          de livraison est communiqué au client lors de la prise de commande ;
          il dépend principalement du nombre de commandes en cours et de la zone
          de livraison sélectionnée. La livraison est offerte à partir de 10k un
          achat. Le coût de livraison est fixe pour tous les quartiers et toutes
          les commandes inférieures à 500F CFA TTC. Réception de votre colis.
        </p>
        <p>
          Suite à de nombreuses tentatives de fraudes signalées, nous
          travaillerons en étroite collaboration avec nos transporteurs. Nous
          avons accès en permanence au suivi GPS du chauffeur et pouvons ainsi
          voir en temps réel si ce dernier en délai est bloqué à des colis chez
          vous dans une contestation de livraison à domicile.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-lg font-bold text-dark mb-4">Produits Fragiles</h2>
        <p className="mb-4 text-gray-700">
          Nous avertissons nos clients que les produits fragiles comme les
          contenants peuvent subir quelques impacts de choc pendant le transport
          malgré tous les efforts de la part pour protéger les bouteilles et
          boissons. Nous ne pouvons pas faire d’échanges dans qu’une entête a
          pris un choc.
        </p>
        <p className="mb-4 text-gray-700">
          Les produits chocolatés comme les lapins, oursons, et œufs ne peuvent
          être échangés si ils arrivent cassés. Ils font partie des produits
          fragiles qui peuvent subir des chocs lors du transport malgré les
          protections.
        </p>
        <p className="mb-4 text-gray-700">
          Les chocolats sont susceptibles d’arriver fondus pendant la forte
          période de chaleur que nous rencontrons pendant l’été, nous ne pouvons
          garantir que les produits arrivent intacts. C’est un risque du client
          et aucune réclamation ne sera prise en compte en cas de chocolat
          fondu.
        </p>
        <p>
          Les bouteilles en verre peuvent casser malgré une protection
          renforcée. Pas de réclamation à la livraison du colis, aucun
          remboursement ne sera accepté en cas de casse. Vous pouvez prendre le
          temps d’ouvrir votre colis devant le livreur, c’est dans votre droit.
          Toutes vos commandes sont protégées, cependant nous avertissons que si
          vous commandez des canettes, nous ne pouvons être tenus responsables
          des éventuelles chocs que peuvent subir les colis pendant le
          transport.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-lg font-bold text-dark mb-4">
          Politique des Retours
        </h2>
        <p className="mb-4 text-gray-700">
          Les articles achetés auprès de HappyChill seront être retournés dans
          les 14 jours suivant la réception des biens dans la plupart des cas,
          le produit doit être dans le même état que celui dans lequel vous
          l’avez reçu et ne doit pas être endommagé d’une quelconque manière.
          Aucun produit ne devra avoir été ouvert.
        </p>
        <p>
          Vous êtes autorisé à sortir le produit de son emballage, à moins que
          celui-ci ne soit scellé. Si vous décidez de retourner les articles,
          vous devrez nous le faire savoir en remplissant le formulaire de
          retour sur notre site web.
        </p>
        <p>
          Vous pouvez également nous faire savoir que vous souhaitez retourner
          votre commande en nous envoyant un e-mail à l’adresse suivante
          info@happychiill.com. Veuillez noter que vous devez nous renvoyer les
          articles dans les 14 jours suivant notre notification de retour.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-lg font-bold text-dark mb-4">
          Articles Non Remboursables
        </h2>
        <p className="mb-4 text-gray-700">
          Les types de produits suivants ne peuvent pas être retournés.
          Conformément à l’article L. 121-20-2 du Code de la consommation, il
          est rappelé au Client que le droit de rétractation n’avant ne peut
          être exercé sur les contrats de fourniture de biens susceptibles de se
          détériorer ou de se périmer rapidement.
        </p>
        <ul className="list-disc ml-8">
          <li>
            Les produits périssables ou à durée de conservation limitée, tel que
            Snacks, boissons, bonbons, céréales ou avec une date très courte au
            dépossé (ANTI-GASP)
          </li>
          <li>
            Les produits qui ne peuvent pas être retournés après ouverture pour
            des raisons d’hygiène, tel que Alimentation et Boissons
          </li>
          <li>
            Les box Candy, Chocolat, miqo et alimentaire ne peuvent pas être
            reprises
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Livraison;
