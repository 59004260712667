const Paiement = () => {
  return (
    <div className="bg-white text-left container mx-auto p-8">
      <h1 className="text-2xl font-semibold text-dark mb-6">Paiements</h1>

      <section className="mb-8">
        <h2 className="text-lg font-bold text-dark mb-4">Paiement sécurisé</h2>
        <p className="mb-4 text-gray-700">
          <strong>Paiement sécurisé sur HappyChiill</strong>
        </p>
        <p className="mb-4 text-gray-700">
          Chez HappyChiill, nous garantissons un paiement sécurisé avec nos
          différents prestataires de paiement. Vous pouvez régler votre commande
          en espèce, Mobile money, carte bancaire, Visa, Mastercard, American
          Express, Paypal.
        </p>
        <p className="mb-4 text-gray-700">
          Pour un maximum de confort et une garantie de sécurité aussi bien pour
          vous que pour nous, nos solutions sont toutes équipées de
          l'identification 3DS2.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-lg font-bold text-dark mb-4">
          Paiement par Carte Bancaire avec HappyPay / Mollie:
        </h2>
        <p className="mb-4 text-gray-700">
          Retrouvez la liste des moyens de paiement acceptés que vous soyez
          aussi bien au Sénégal ou depuis l'étranger, nous acceptons 90% des
          solutions de paiement existantes:
        </p>
        <p className="font-bold">
          <span className="font-normal text-dark">=&gt;</span> Orange Money /
          Wave / Free Money / Visa / Mastercard / Paypal / American Express /
          Apple Pay / Cash / Apple Pay
        </p>
      </section>
    </div>
  );
};

export default Paiement;
