import { useNavigate, useParams } from "react-router-dom";
import { ChevronBackIcon } from "../assets";
import ProductList from "./company/card/productList";

const Product = () => {
  let { name } = useParams();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full flex flex-col justify-center items-center gap-5 px-3 pt-[2rem] py-8 font-apple-system lg:px-12">
      <div className="flex flex-row justify-between items-center w-full">
        <button onClick={handleBack}>
          <ChevronBackIcon width={"31"} height={"31"} color={"#EA542B"} />
        </button>
        <span className="font-bold text-base lg:text-4xl  text-customOrange uppercase absolute left-1/2 transform -translate-x-1/2">
          {name}
        </span>
      </div>

      <ProductList />
    </div>
  );
};

export default Product;
