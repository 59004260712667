import { useState, useEffect } from "react";
import { useCart } from "../../../contexts/cart";
import { Link, useParams } from "react-router-dom";
import { fetchRequest } from "../../../common/request";
import { useAddress } from "../../../contexts/localisation";
import { getImageUrl } from "../../../lib/utils";

interface Product {
	isLoading: boolean;
	products: any[];
}

const ProductList: React.FC = () => {
	const { quantities, addToCart, removeFromCart } = useCart();
	const { category } = useAddress();
	let { name }: any = useParams();
	const [data, setData] = useState<Product>({
		isLoading: false,
		products: [],
	});

	useEffect(() => {
		setData({ isLoading: true, products: [] });
		const getProducts = async () => {
			try {
				const response = await fetchRequest(
					`product/category/${category?.id}`
				);
				setData({ isLoading: false, products: response.data });
			} catch (e) {
				console.error(e);
				setData({ isLoading: false, products: [] });
			}
		};
		getProducts();
	}, [category]);

	const { isLoading, products } = data;

	return (
		<>
			{!isLoading ? (
				<>
					{products && products.length > 0 ? (
						<div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8 pt-[2rem]">
							{products.map((product: any) => (
								<div
									key={product.id}
									className="rounded-2xl overflow-hidden border-2 border-black border-opacity-5"
								>
									<Link
										to={`/products/${name}/${product.id}`}
										state={product}
									>
										<img
											src={getImageUrl(
												product.imageUrl
											)}
											alt={
												product.name
											}
											className="w-full pt-4 object-contain h-64"
										/>
									</Link>
									<div className="lg:px-4 lg:py-4 pt-4 lg:h-[6rem] w-full  border-t-2 border-black border-opacity-5 ">
										<div className="flex flex-col lg:flex-row justify-between lg:w-full gap-4">
											<div className="w-full  flex flex-col justify-center">
												<span className="font-bold text-base lg:text-xl  text-[#F7A48E]  ">
													{
														product.price
													}{" "}
													CFA
												</span>
												<span className="font-bold text-base self-center  text-black w-[100%] lg:w-25">
													{
														product.name
													}
												</span>
											</div>
											{quantities[
												product
													.id
											] ? (
												<div className="flex bg-[#EA542B] text-center items-center h-[2.5rem] mt-3">
													<button
														onClick={() =>
															removeFromCart(
																product.id
															)
														}
													>
														<span className="text-sm lg:text-2xl text-white font-bold px-3">
															-
														</span>
													</button>
													<span className="w-full h-text-sm lg:text-xl text-white font-bold">
														{
															quantities[
																product
																	.id
															]
														}
													</span>
													<button
														onClick={() =>
															addToCart(
																product.id
															)
														}
													>
														<span className="text-sm lg:text-2xl px-3 text-white font-bold">
															+
														</span>
													</button>
												</div>
											) : (
												<button
													onClick={() =>
														addToCart(
															product.id
														)
													}
													className="bg-[#EA542B] w-[12rem] lg:w-[10rem] h-10 mt-3"
												>
													<span className="lg:w-full h-text-sm lg:text-base text-white font-bold">
														Ajouter
														+
													</span>
												</button>
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="w-full bg-blue-100 max-w-3xl mx-auto py-3 mt-3 rounded">
							<p>
								Aucun produit trouvé pour cette
								catégorie
							</p>
						</div>
					)}
				</>
			) : (
				<div className="grid grid-cols-2 lg:grid-cols-4 gap-8 pt-[2rem]">
					{/* Show skeleton loading here */}
					{[...Array(8)].map((_, index) => (
						<div
							key={index}
							className="animate-pulse flex-1"
						>
							<div className="bg-slate-200 h-48 min-w-64 flex-1 mb-2"></div>
							<div className="flex-1 space-y-6 py-1">
								<div className="h-8 bg-slate-200 rounded"></div>
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default ProductList;
