import { useCart } from "../contexts/cart";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteIcon, PositionIcon } from "../assets";
import { useAddress } from "../contexts/localisation";
import Track from "./track";
import { fetchRequest } from "../common/request";

const Cart = () => {
  const navigate = useNavigate();
  const { quantities, setOrderInfo, removeFromCart } = useCart();
  const { address, openCart, setOpenCart } = useAddress();
  const [active, setActive] = useState<number>(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [products, setProducts] = useState<[]>([]);

  const getProducts = async () => {
    try {
      const response = await fetchRequest(`product`);
      setProducts(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  let total = 0;

  const addedProducts = products.filter(
    (product: any) => quantities[product.id]
  );

  const items = addedProducts.map((product: any) => {
		const quantity = quantities[product.id] || 0;
		const subtotal = product.price * quantity;
		total += subtotal;

		return (
			<div
				className="flex flex-row justify-between items-center w-full py-2"
				key={product.id}
			>
				<div>
					<span className="text-white text-base lg:text-xl font-semibold">
						{quantity} X
					</span>
					<span className="text-white ms-1 text-base lg:text-xl  font-semibold">
						{product.name}
					</span>
				</div>
				<span className="text-white text-base lg:text-xl font-semibold">
					{subtotal} F{" "}
				</span>
				<span onClick={() => removeFromCart(product.id)}>
					<DeleteIcon
						width={"22"}
						height={"20"}
						color={"white"}
					/>
				</span>
			</div>
		);
  });

  const handleBack = () => {
		setOpenCart(false);
		navigate("/products/all", { replace: true });
  };
  const handleActive = (index: number) => {
		setActive(index);
  };

  const handleInputChange =
		(setStateFunc: React.Dispatch<React.SetStateAction<string>>) =>
		(
			event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
		) => {
			setStateFunc(event.target.value);
		};

  const handleTrack = () => {
		handleActive(2);
		const validatedProducts = addedProducts.map((product: any) => {
			const quantity = quantities[product.id] || 0;
			return {
				...product,
				quantity,
			};
		});

		const totalPrice = validatedProducts.reduce(
			(total, product) => total + product.price * product.quantity,
			0
		);

		setOrderInfo({
			name: fullName,
			phoneNumber,
			address,
			validatedProducts,
			totalPrice,
		});
  };

  return (
		<>
			<Transition show={openCart}>
				<Dialog className="relative z-10" onClose={handleBack}>
					<TransitionChild
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<div className="fixed inset-0 z-10 w-screen self-center overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<>
								{active === 0 && (
									<TransitionChild
										enter="ease-out duration-300"
										enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
										enterTo="opacity-100 translate-y-0 sm:scale-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100 translate-y-0 sm:scale-100"
										leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									>
										<DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[60%]">
											<div className="bg-customOrange px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
												<div className="sm:flex sm:items-start">
													<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
														<DialogTitle
															as="h3"
															className=" font-semibold leading-6 text-white text-center text-lg lg:text-2xl"
														>
															Votre
															panier
															(
															{
																addedProducts.length
															}{" "}
															{addedProducts.length >
															1
																? "produits"
																: "produit"}

															)
														</DialogTitle>
														{addedProducts.length ===
														0 ? (
															<div className="px-2 py-4 h-[30%] items-center justify-center text-center text-white">
																Le
																panier
																est
																vide.
																Veuillez
																ajouter
																des
																articles.
															</div>
														) : (
															<div className="pt-4 w-full">
																<div className="px-2 py-2 h-[30%]">
																	{
																		items
																	}
																</div>
																<div className="border-t-2 border-black border-opacity-5">
																	<div className="w-full pt-2 flex flex-row justify-between items-center">
																		<span className="text-white text-base lg:text-xl font-semibold">
																			Total
																		</span>
																		<span className="text-white text-base lg:text-xl font-semibold">
																			{
																				total
																			}{" "}
																			F
																		</span>
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>

											<div className="bg-customOrange  px-4 py-3 flex flex-col lg:flex-row gap-2 md:justify-between items-center">
												{addedProducts.length ===
												0 ? (
													<button
														type="button"
														className="inline-flex w-full text-center rounded-md bg-white px-3 py-2 text-base lg:text-xl font-semibold text-customOrange shadow-sm hover:bg-white"
														onClick={
															handleBack
														}
													>
														Ajouter
														des
														produits
													</button>
												) : (
													<>
														<button
															type="button"
															className="w-full text-center rounded-md bg-white px-3 py-2 text-base lg:text-xl font-semibold text-customOrange shadow-sm hover:bg-white"
															onClick={
																handleBack
															}
														>
															Ajouter
															des
															produits
														</button>
														<button
															type="button"
															className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-base lg:text-xl font-semibold text-customOrange shadow-sm ring-1 ring-inset ring-customOrange hover:bg-white"
															onClick={() =>
																handleActive(
																	1
																)
															}
															data-autofocus
														>
															Finaliser
															ma
															commande
														</button>
													</>
												)}
											</div>
										</DialogPanel>
									</TransitionChild>
								)}
								{active === 1 && (
									<TransitionChild
										enter="ease-out duration-300"
										enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
										enterTo="opacity-100 translate-y-0 sm:scale-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100 translate-y-0 sm:scale-100"
										leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									>
										<DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[40%]">
											<div className="bg-customOrange px-4 ">
												<div className="sm:flex sm:items-start">
													<div className="w-full">
														<DialogTitle
															as="h3"
															className=" font-semibold leading-6 text-white text-center text-2xl py-4"
														>
															Tes
															informations
														</DialogTitle>
														<div className="w-full border-t-2 border-black border-opacity-5 pt-8  space-y-8 px-2 lg:px-12 flex flex-col items-center justify-center">
															<input
																type="tel"
																placeholder="Votre numéro de Téléphone"
																value={
																	phoneNumber
																}
																onChange={handleInputChange(
																	setPhoneNumber
																)}
																className="placeholder:text-center text-left focus:outline-none w-full  py-4 px-3 placeholder-black rounded-lg bg-neutral-50 text-base font-extralight"
															/>
															<input
																type="text"
																placeholder="Votre Nom"
																value={
																	fullName
																}
																onChange={handleInputChange(
																	setFullName
																)}
																className="placeholder:text-center text-left focus:outline-none w-full py-4 px-3  placeholder-black rounded-lg bg-neutral-50 text-base font-extralight"
															/>
															<div className="flex items-center">
																<PositionIcon
																	width={
																		"37"
																	}
																	height={
																		"37"
																	}
																	color={
																		"#FFFFFF"
																	}
																/>
																<span className="w-full text-sm lg:text-base text-white ">
																	{
																		address
																	}
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="bg-customOrange w-full px-4 py-3 sm:flex sm:flex-row sm:px-6 flex flex-row justify-center items-center">
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-lg lg:text-2xl font-semibold text-customOrange shadow-sm ring-1 ring-inset ring-customOrange hover:bg-white sm:mt-0 sm:w-auto"
													onClick={
														handleTrack
													}
													data-autofocus
												>
													Valider
													ma
													commande
												</button>
											</div>
										</DialogPanel>
									</TransitionChild>
								)}
								{active === 2 && <Track />}
							</>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
  );
};

export default Cart;
