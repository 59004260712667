import React from "react";
import {
  CashIcon,
  FacebookIcon,
  FreeIcon,
  HappyLogo,
  InstagramIcon,
  OrangeIcon,
  WaveIcon,
} from "../../../assets";
import { Link } from "react-router-dom";
import { HubPaths } from "../../../routers/paths";

const Foot = () => {
  return (
		<div className="grid grid-cols-1 lg:grid-cols-4 gap-4 w-full lg:w-[70%] justify-between h-full py-8 space-x-2">
			<div className="flex flex-col items-center">
				<a href="/">
					<HappyLogo
						className="w-44 h-20 lg:w-[150px] lg:h-[49px]"
						color={"#FFFFFF"}
					/>
				</a>
				{/* <a href="/"><LogoMobileIcon width={"128"} height={"167"} color={"#FFFFFF"} /></a> */}
			</div>
			<div className="flex flex-col">
				<span className="text-sm lg:text-base text-white font-bold">
					Commande en ligne
				</span>
				<span className="text-sm lg:text-base text-white font-normal">
					Lundi au Dimanche
				</span>
				<span className="text-sm lg:text-base text-white font-normal">
					24h/24h
				</span>
				<div className="flex flex-row space-x-2 pt-2 self-center ">
					<WaveIcon
						width={"42"}
						height={"42"}
						color={"none"}
					/>
					<OrangeIcon
						width={"42"}
						height={"42"}
						color={"none"}
					/>
					<FreeIcon
						width={"96"}
						height={"42"}
						color={"none"}
					/>
					<CashIcon
						width={"80"}
						height={"53"}
						color={"none"}
					/>
				</div>
			</div>
			<div className="flex flex-col">
				<span className="text-sm lg:text-base text-white font-bold">
					Liens
				</span>
				<div className="flex flex-col pt-2 lg:pt-0">
					<Link
						to={HubPaths.cgv}
						className="text-sm lg:text-base text-white font-light"
					>
						CGV
					</Link>
					<Link
						to={HubPaths.paiement}
						className="text-sm lg:text-base text-white font-light"
					>
						Paiement
					</Link>
					<Link
						to={HubPaths.livraison}
						className="text-sm lg:text-base text-white font-light"
					>
						Livraison
					</Link>
					<Link
						to={HubPaths.security}
						className="text-sm lg:text-base text-white font-light"
					>
						Sécurité
					</Link>
				</div>
			</div>
			<div className="flex flex-col">
				<span className="text-sm lg:text-base text-white font-bold">
					Contact
				</span>
				<div className="flex flex-col pt-2 lg:pt-0">
					<span className="text-sm lg:text-base text-white font-light">
						contact@hapychiill.com
					</span>
					<div className="flex flex-row pt-2 space-x-2 self-center">
						<a
							target="_blank"
							href="https://www.facebook.com/profile.php?id=61556319181108"
							rel="noopener noreferrer"
						>
							<FacebookIcon
								width={"34"}
								height={"34"}
								color={"none"}
							/>
						</a>
						<a
							target="_blank"
							href="https://www.instagram.com/happychiill/?igsh=MXRpbDQ5bndqcTg2cg%3D%3D&utm_source=qr"
							rel="noopener noreferrer"
						>
							<InstagramIcon
								width={"34"}
								height={"34"}
								color={"none"}
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
  );
};

export default Foot;
