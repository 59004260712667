import { HubPaths } from "./paths";
import Home from "../app/home";
import Category from "../app/category";
import Product from "../app/product";
import ProductDetails from "../app/company/card/productDetails";
import TrackingOrder from "../app/TrackingOrder";
import CGV from "../app/company/conditions/CGV";
import Paiement from "../app/company/conditions/Paiement";
import Livraison from "../app/company/conditions/Livraison";
import Security from "../app/company/conditions/Security";

export const navigationPaths = [
	{
		path: HubPaths.Home,
		element: <Home />,
	},
	{
		path: HubPaths.Category,
		element: <Category />,
	},
	{
		path: HubPaths.Product,
		element: <Product />,
	},
	{
		path: HubPaths.ProductDetails,
		element: <ProductDetails />,
	},
	{
		path: HubPaths.tracking,
		element: <TrackingOrder />,
	},
	{
		path: HubPaths.cgv,
		element: <CGV />,
	},
	{
		path: HubPaths.paiement,
		element: <Paiement />,
	},
	{
		path: HubPaths.livraison,
		element: <Livraison />,
	},
	{
		path: HubPaths.security,
		element: <Security />,
	},
];
