import axios, { AxiosRequestConfig } from "axios";

// export const apiUrl = "https://gateway-happychiill.onrender.com";
export const apiUrl = "https://herokuconnect.happychiill.com";

export const postRequest = async (path: string, body: any) => {
  const token = localStorage.getItem("token");
  const config: AxiosRequestConfig = {
    method: "post",
    url: `${apiUrl}/${path}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: body,
  };
  const request = await axios.request(config);
  return request.data;
};

export const putRequest = async (path: string, body?: any) => {
  const token = localStorage.getItem("token");
  const config: AxiosRequestConfig = {
    method: "put",
    url: `${apiUrl}/${path}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    proxy: {
      protocol: "https",
      host: `${apiUrl}`,
      port: 443,
    },
    data: body,
  };
  const request = await axios.request(config);
  return request.data;
};

export const fetchRequest = async (path: string, queryParams?: any) => {
  const token = localStorage.getItem("token");
  const config: AxiosRequestConfig = {
    method: "get",
    url: `${apiUrl}/${path}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: queryParams,
    proxy: {
      protocol: "https",
      host: `${apiUrl}`,
      port: 443,
    },
  };
  try {
    const request = await axios.request(config);

    if (!request.data) {
      throw new Error(`HTTP error! Status: ${request.status}`);
    }
    return request.data;
  } catch (error) {
    console.error("Erreur de requête API:", error);
    throw error;
  }
};

export const getRequest = async (path: string, id: string) => {
  const token = localStorage.getItem("token");
  const config: AxiosRequestConfig = {
    method: "get",
    url: `${apiUrl}/${path}/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    proxy: {
      protocol: "https",
      host: "ghub-portal-dev0.gdi-advisors.com",
      port: 443,
    },
  };
  try {
    const request = await axios.request(config);
    return request.data;
  } catch (error) {
    console.error("Erreur de requête API:", error);
    throw error;
  }
};
