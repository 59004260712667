import { Navbar } from "./navbar";
import Foot from "../foot";
import { useLocation } from "react-router-dom";

export const AdminLayout = ({ children }: any) => {
  const { pathname } = useLocation();
  const display = !(pathname === "/");

  return (
    <div className="w-screen min-h-screen h-screen flex justify-center font-apple-system">
      <div className="relative w-full h-full flex flex-col">
        {display ? (
          <div className="flex flex-col h-full w-full">
            <div className="w-full">
              <Navbar changed={display} />
            </div>
            <main className="w-full flex flex-col lg:flex-row flex-grow">
              <div className="w-full bg-white flex-grow rounded-b-md">
                {children}
              </div>
            </main>
            <div className="w-full flex flex-col justify-center items-center bg-customOrange py-3 mt-auto">
              <Foot />
              <span className="text-sm lg:text-base text-white font-light">
                Copyright © 2024 Innovatelq. All Rights Reserved.
              </span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full h-full">
            <Navbar changed={display} />
            <main className="w-full flex flex-col lg:flex-row flex-grow">
              <div className="w-full bg-white flex-grow rounded-b-md">
                {children}
              </div>
            </main>
          </div>
        )}
      </div>
    </div>
  );
};
