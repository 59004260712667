import React, { useRef, useEffect, useState } from "react";
import * as maptilersdk from "@maptiler/sdk";
import "@maptiler/sdk/dist/maptiler-sdk.css";


const TrackingOrder = () => {
	const mapContainer = useRef(null);
	const map = useRef<maptilersdk.Map | null>(null);
	const tokyo = { lng: 139.753, lat: 35.6844 };
	const [zoom] = useState(14);
	maptilersdk.config.apiKey = "NiilP0fg65pIvKx2rjlU";

	useEffect(() => {
		if (!map.current && mapContainer.current) {
			map.current = new maptilersdk.Map({
				container: mapContainer.current,
				style: maptilersdk.MapStyle.STREETS,
				center: [tokyo.lng, tokyo.lat],
				zoom: zoom,
			});

			new maptilersdk.Marker({ color: "#FF0000" })
				.setLngLat([139.7525, 35.6846])
				.addTo(map.current);
		}
	}, [tokyo.lng, tokyo.lat, zoom]);

	return (
		<div>
			<div ref={mapContainer} className="map" />
		</div>
	);
};

export default TrackingOrder;
