import { useNavigate } from "react-router-dom";
import { VectorIcon } from "../../../assets";
import { useAddress } from "../../../contexts/localisation";
import { getImageUrl } from "../../../lib/utils";
export interface Category {
  id: string;
  name: string;
  image: string;
  isAdultOnly: boolean;
}

interface Props {
  categories: Category[];
}

const CategoriesList: React.FC<Props> = ({ categories }) => {
  const navigate = useNavigate();
  const { setCategory, setIsOpenConsentModal } = useAddress();

  const handleClickCategory = (category: Category) => {
    if (category.isAdultOnly) {
      setIsOpenConsentModal(true);
      setCategory(category);
    } else {
      navigate(`/products/${category.name}`);
      setCategory(category);
    }
  };

  return (
		<>
			{categories && categories?.length > 0 ? (
				categories.map((category) => (
					<div
						key={category.id}
						className="max-w-sm rounded overflow-hidden shadow-lg "
					>
						<img
							src={getImageUrl(category?.image)}
							alt={category.name}
							className="w-full h-48 object-contain"
						/>
						<button
							onClick={() =>
								handleClickCategory(category)
							}
							className="lg:px-6 px-6 py-4 pt-4  bg-[#EA542B] w-full"
						>
							<div className="flex justify-between">
								<span className="font-bold text-base sm:text-xl text-white uppercase">
									{category.name}
								</span>
								<span className="self-center">
									<VectorIcon
										width={"18"}
										height={"12"}
										color={"white"}
									/>
								</span>
							</div>
						</button>
					</div>
				))
			) : (
				<div className="w-full bg-blue-100 max-w-3xl mx-auto py-3  px-5 mt-3 rounded">
					<p>Aucune catégorie trouvée</p>
				</div>
			)}
		</>
  );
};

export default CategoriesList;
