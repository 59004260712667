import champagne from "../assets/icons/chateau-amandine 1.png";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useAddress } from "../../../contexts/localisation";

const ValidateAdult = () => {
  const navigate = useNavigate();
  const { isOpenConsentModal, setIsOpenConsentModal, category } = useAddress();

  const onConsent = () => {
    navigate(`/products/${category?.name}`);
    setIsOpenConsentModal(false);
  };

  const retrieveClick = () => {
    setIsOpenConsentModal(false);
  };

  return (
    <>
      <Transition show={isOpenConsentModal}>
        <Dialog
          className="relative z-10"
          onClose={() => setIsOpenConsentModal(false)}
        >
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 self-center w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className=" relative transform overflow-hidden  bg-customOrange text-left shadow-xl transition-all sm:py-8 sm:w-full sm:max-w-[70%] lg:max-w-[50%]">
                  <div className="bg-customOrange  px-4 py-4  sm:flex sm:flex-row sm:px-6 flex flex-row justify-between gap-2 items-center">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center  bg-white px-3 py-2 text-sm  lg:text-xl font-semibold text-customOrange shadow-sm hover:bg-white sm:ml-3 sm:w-auto"
                      onClick={onConsent}
                    >
                      J’ai plus de 18 ans
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm lg:text-xl font-semibold text-white sm:mt-0 sm:w-auto"
                      data-autofocus
                      onClick={retrieveClick}
                      style={{
                        background: "none",
                        border: "none",
                        boxShadow: "none",
                        padding: 0,
                      }}
                    >
                      j’ai moins de 18 ans, je retourne au menu
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ValidateAdult;
