import React from "react";
import { Route, Routes } from "react-router-dom";
import { AddressProvider } from "../contexts/localisation";
import { AdminLayout } from "../app/company/layout/layout";
import { navigationPaths } from "./pages";

const KRoutes: React.FC = () => {
  return (
    <>
      <AddressProvider>
        <AdminLayout>
          <Routes>
            {navigationPaths.map((navigation: any) => (
              <Route
                key={navigation.path}
                path={navigation.path}
                element={navigation.element}
              />
            ))}
          </Routes>
        </AdminLayout>
      </AddressProvider>
    </>
  );
};

export default KRoutes;
