const CGV = () => {
  return (
    <div className="container mx-auto text-left p-6">
      <h1 className="text-2xl font-semibold text-dark">
        Conditions Générales CGV
      </h1>

      <div className="mt-8 space-y-8 pb-12">
        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 1 - Définitions
          </h2>
          <p className="text-gray-700">
            Les présentes Conditions Générales de Vente (ci-après les « CGV »)
            sont proposées par INNOVATELQ Holding Capital social de 2 500 000
            FRCFA Numéro de TVA: SN DKR2024B6646– Les almadies zone 4 villa 259
            - (ci-après « HappyChiill »). On désignera par la suite :
          </p>
          <ul className="list-disc ml-5 mt-3 text-gray-700">
            <li>
              <strong>« Site »</strong> : le site{" "}
              <a href="https://happychiill.com" className="text-blue-500">
                https://happychiill.com
              </a>{" "}
              et l'ensemble de ses pages.
            </li>
            <li>
              <strong>« Produits » ou « Services »</strong> : l'ensemble des
              produits (matériels) et services (prestations) qu'il est possible
              d'acheter ou auxquels il est possible de souscrire sur le Site.
            </li>
            <li>
              <strong>« Vendeur »</strong> : Happychiill, personne morale ou
              physique, proposant ses Produits ou Services sur le Site.
            </li>
            <li>
              <strong>« Client »</strong> : l'internaute, particulier ou
              professionnel, effectuant un achat de Produit(s) ou Service(s) sur
              le Site.
            </li>
            <li>
              <strong>« Consommateur »</strong>, conformément à la définition de
              l'article préliminaire du Code de la consommation : « toute
              personne physique qui agit à des fins qui n'entrent pas dans le
              cadre de son activité commerciale, industrielle, artisanale ou
              libérale ».
            </li>
          </ul>
          <p className="text-gray-700 mt-3">
            L'internaute visitant le Site et intéressé par les Produits et
            Services proposés par le Vendeur est invité à lire attentivement ces
            CGV, à les imprimer et/ou à les sauvegarder sur un support durable,
            avant de procéder à une commande sur le Site. Le Client reconnaît
            avoir pris connaissance des CGV et les accepte intégralement et sans
            réserve.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 2 - Application des CGV et objet du Site
          </h2>
          <p className="text-gray-700">
            Le Vendeur se réserve le droit de modifier à tout moment les CGV en
            publiant une nouvelle version de ces dernières sur le Site. Les CGV
            applicables au Client sont celles en vigueur au jour de sa commande
            sur le Site. Les informations légales concernant l'hébergeur et
            l'éditeur du Site, la collecte et le traitement des données
            personnelles et les conditions d'utilisation du Site sont fournies
            dans les conditions générales d'utilisation, les mentions légales et
            la charte de données du présent Site.
          </p>
          <p className="text-gray-700">
            Le présent Site propose la vente en ligne de . Le Site est d’accès
            libre et gratuit à tout Client. L'acquisition d'un Produit ou d’un
            Service suppose l’acceptation, par le Client, de l’intégralité des
            présentes CGV, qui reconnaît du même fait en avoir pris pleinement
            connaissance. Cette acceptation pourra consister par exemple, pour
            le Client, à cocher la case correspondant à la phrase d'acceptation
            des présentes CGV, ayant par exemple la mention « Je reconnais avoir
            lu et accepté l’ensemble des conditions générales du Site ». Le fait
            de cocher cette case sera réputé avoir la même valeur qu’une
            signature manuscrite de la part du Client.
          </p>
          <p className="text-gray-700">
            L'acceptation des présentes CGV suppose de la part des Clients
            qu'ils jouissent de la capacité juridique nécessaire pour cela. Si
            le Client est mineur ou ne dispose pas de cette capacité juridique,
            il déclare avoir l'autorisation d'un tuteur, d'un curateur ou de son
            représentant légal. Le Client reconnaît la valeur de preuve des
            systèmes d'enregistrement automatique du Vendeur et, sauf pour lui
            d'apporter une preuve contraire, il renonce à les contester en cas
            de litige.
          </p>
          <p className="text-gray-700">
            L'Editeur met à la disposition du Client, sur son Site, une charte
            de confidentialité spécifiant l’ensemble des informations afférentes
            à l’utilisation des données à caractère personnel du Client
            collectées par l'Editeur et aux droits dont le Client dispose
            vis-à-vis de ces données personnelles. La politique de
            confidentialité des données fait partie des CGV. L'acceptation des
            présentes CGV implique par conséquent l'acceptation de la politique
            de confidentialité des données.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 3 - Création d'un compte client
          </h2>
          <p className="text-gray-700">
            La création d’un « compte client » est un préalable indispensable à
            toute commande du Client sur le présent Site. A cette fin, le Client
            sera invité à fournir un certain nombre d’informations personnelles
            tels que ses nom et prénom, son adresse email, son adresse postale
            et son numéro de téléphone, cette liste n’étant pas exhaustive. A ce
            titre, le Client s’engage à fournir des informations exactes. Le
            Client est responsable de la mise à jour de ses données. Il doit
            donc aviser sans délai le Vendeur en cas de changement. Le Client
            est le seul responsable de la véracité, de l'exactitude et de la
            pertinence des données fournies.
          </p>
          <p className="text-gray-700">
            Le Client inscrit au Site a la possibilité d'y accéder en se
            connectant grâce à ses identifiants (adresse e-mail définie lors de
            son inscription et mot de passe) ou éventuellement en utilisant des
            systèmes tels que des boutons de connexion tiers de réseaux sociaux.
            Le Client est entièrement responsable de la protection du mot de
            passe qu’il a choisi. Il est encouragé à utiliser des mots de passe
            complexes. En cas d’oubli de mot de passe, le Client a la
            possibilité d’en générer un nouveau.
          </p>
          <p className="text-gray-700">
            Ce mot de passe constitue la garantie de la confidentialité des
            informations contenues dans sa rubrique « mon compte » et le Client
            s'interdit donc de le transmettre ou de le communiquer à un tiers. A
            défaut, le Vendeur ne pourra être tenu pour responsable des accès
            non autorisés au compte d'un Client.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 4 - Modalité de souscription des commandes et descriptif du
            processus d’achat
          </h2>
          <p className="text-gray-700">
            Les Produits et Services proposés sont ceux qui figurent dans le
            catalogue publié sur le Site. Ces Produits et Services sont proposés
            dans la limite des stocks disponibles. Chaque Produit est accompagné
            d’un descriptif établi par le Vendeur en fonction des descriptifs
            fournis par le fournisseur.
          </p>
          <p className="text-gray-700">
            Les photographies des Produits du catalogue reflètent une image
            fidèle des Produits et Services proposés mais ne sont pas
            constitutives d’un engagement contractuel dans la mesure où elles ne
            peuvent pas assurer une parfaite similitude avec les Produits
            physiques. On définira ci-dessous comme « Panier » l’objet
            immatériel regroupant l’ensemble des Produits ou Services
            sélectionnés par le Client du Site en vue d’un achat en ayant cliqué
            sur ces éléments. Afin de procéder à sa commande, le Client choisit
            le ou les Produit(s) qu’il souhaite commander en les ajoutant à son
            « Panier » dont le contenu peut être modifié à tout moment.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 5 - Prix et modalités de paiement
          </h2>
          <p className="text-gray-700">
            Sauf mention contraire, les prix figurant dans le catalogue sont des
            prix entendus en Franc cfa toutes taxes comprises (TTC), tenant
            compte de la TVA applicable au jour de la commande et hors
            éventuelle participation aux frais de traitement et d'expédition.
            HappyChiill se réserve le droit à tout moment de modifier ses prix
            et de répercuter, si applicable, tout changement du taux de TVA en
            vigueur sur le prix des Produits ou Services proposés sur le Site.
          </p>
          <p className="text-gray-700">
            Le Client peut passer commande sur le présent Site et peut effectuer
            son règlement par Mobile money, en espèce, Carte Bancaire, ou
            Paypal. Les paiements par carte bancaire se font au moyen de
            transactions sécurisées fournies par un prestataire de plateforme de
            paiement en ligne. Le présent Site n’a accès à aucune donnée
            relative aux moyens de paiement du Client. Le paiement est effectué
            directement entre les mains de la banque ou du prestataire de
            paiement recevant le paiement du Client.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 6 - Livraisons
          </h2>
          <p className="text-gray-700">
            Les frais de livraison seront indiqués au Client avant tout
            règlement. Le Site n’a pas de limitation géographique de livraison,
            les commandes peuvent être expédiées partout à Dakar. En cas de
            livraison d'un Produit en dehors du territoire de Dakar et dans le
            Sénégal, le Client se déclare l'importateur du Produit et accepte
            qu'en pareil cas le Vendeur peut être dans l'impossibilité
            matérielle de lui communiquer une information exacte sur le montant
            total des frais afférents aux droits et formalités de douanes ou des
            taxes à l'importation applicables dans le pays où la livraison du
            Produit est demandée.
          </p>
          <p className="text-gray-700">
            Sauf mention contraire affichée sur le Site lors du processus de
            commande ou dans la description des Produits commandés, le Vendeur
            s’engage dans tous les cas à livrer les Produits dans un délai
            maximum de 14 jours après la conclusion du contrat avec un Client
            Consommateur. En moyenne les colis sont livrés entre 24 et 72h.
          </p>
          <p className="text-gray-700">
            Le Client peut refuser un colis au moment de la livraison s'il
            constate une anomalie concernant la livraison (avarie, Produit
            manquant par rapport au bon de livraison, colis endommagé, Produits
            cassés…) ; toute anomalie devra alors impérativement être indiquée
            par le Client sur le bon de livraison, sous forme de réserves
            manuscrites, accompagnées de la signature du Client.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 7 - Droit de rétractation et formulaire de rétractation
          </h2>
          <p className="text-gray-700">
            Conformément à l'article L.221-18 du Code de la consommation, et si
            le droit de rétractation est applicable, le Client Consommateur
            dispose d'un délai de quatorze (14) jours ouvrables à compter de la
            date de réception du Produit de sa commande ou de la conclusion du
            contrat pour les prestations de services, pour se rétracter.
          </p>
          <p className="text-gray-700">
            Le Client supportera les frais de renvoi du Produit en cas de
            rétractation, ainsi que le coût de renvoi du Produit si celui-ci, en
            raison de sa nature, ne peut normalement être renvoyé par la Poste.
          </p>
          <p className="text-gray-700">
            Il est recommandé au Client d’effectuer le retour par une solution
            permettant un suivi du colis. Dans le cas contraire, si le colis
            retourné ne parvenait pas au Vendeur, il ne sera pas possible de
            lancer une enquête auprès des services de livraison afin de leur
            demander de localiser ce dernier.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 8 - Application des CGU
          </h2>
          <p className="text-gray-700">
            Les présentes CGU ont pour objet de définir les conditions d'accès
            au Site par les Utilisateurs. L'Editeur se réserve le droit de
            modifier à tout moment les CGU en publiant une nouvelle version de
            ces dernières sur le Site. Les CGU applicables à l'Utilisateur sont
            celles en vigueur au jour de son acceptation.
          </p>
          <p className="text-gray-700">
            Le Site est d'accès libre et gratuit à tout Utilisateur.
            L'acquisition d'un produit ou d'un service, ou la création d'un
            espace membre, ou de manière plus générale la navigation sur le Site
            suppose l'acceptation, par l'Utilisateur, de l'intégralité des
            présentes CGU, qui reconnaît du même fait en avoir pris pleinement
            connaissance.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 9 - Mentions légales, données personnelles et objet du Site
          </h2>
          <p className="text-gray-700">
            Le présent Site est édité par SARL. Les informations légales
            concernant l'hébergeur et l'Editeur du Site, notamment les
            coordonnées et les éventuelles informations de capital et
            d'immatriculation, sont fournies dans les mentions légales du
            présent Site.
          </p>
          <p className="text-gray-700">
            Les informations concernant la collecte et le traitement des données
            personnelles (politique et déclaration) sont fournies dans la charte
            de données personnelles du Site. L'objet du présent Site est
            déterminé comme « boutique en ligne ».
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 10 - Accès et disponibilité du Site
          </h2>
          <p className="text-gray-700">
            L'Editeur fait ses meilleurs efforts pour rendre le Site accessible
            en permanence, sous réserve des opérations de maintenance du Site ou
            des serveurs sur lesquels il est hébergé. En cas d’impossibilité
            d’accès au Site, en raison de problèmes techniques ou de toutes
            natures, l'Utilisateur ne pourra se prévaloir d'un dommage et ne
            pourra prétendre à aucune indemnité.
          </p>
          <p className="text-gray-700">
            L'Editeur du Site n’est tenu que par une obligation de moyens ; sa
            responsabilité ne pourra être engagée pour un dommage résultant de
            l'utilisation du réseau Internet tel que perte de données,
            intrusion, virus, rupture du service, ou autres. L'Utilisateur admet
            expressément utiliser le Site à ses propres risques et sous sa
            responsabilité exclusive.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 11 - Cookies
          </h2>
          <p className="text-gray-700">
            Un « Cookie » peut permettre l'identification de l'Utilisateur du
            Site, la personnalisation de sa consultation du Site et
            l'accélération de l’affichage du Site grâce à l'enregistrement d'un
            fichier de données sur son ordinateur. Le Site est susceptible
            d'utiliser des « Cookies » principalement pour 1) obtenir des
            statistiques de navigation afin d'améliorer l'expérience de
            l'Utilisateur, et 2) permettre l'accès à un compte de membre et à du
            contenu qui n'est pas accessible sans connexion.
          </p>
          <p className="text-gray-700">
            L'Utilisateur reconnaît être informé de cette pratique et autorise
            l'Editeur du Site à y recourir. L'Editeur s'engage à ne jamais
            communiquer le contenu de ces « Cookies » à des tierces personnes,
            sauf en cas de réquisition légale. L'Utilisateur peut refuser
            l'enregistrement de « Cookies » ou configurer son navigateur pour
            être prévenu préalablement à l'acception des « Cookies ».
          </p>
          <p className="text-gray-700">
            Pour ce faire, l'Utilisateur procédera au paramétrage de son
            navigateur :
          </p>
          <ul className="list-disc ml-8 text-gray-700">
            <li>
              Pour Internet Explorer :{" "}
              <a
                href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
                className="text-blue-500"
              >
                http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
              </a>
            </li>
            <li>
              Pour Safari :{" "}
              <a
                href="https://support.apple.com/fr-fr/ht1677"
                className="text-blue-500"
              >
                https://support.apple.com/fr-fr/ht1677
              </a>
            </li>
            <li>
              Pour Google Chrome :{" "}
              <a
                href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en&safe=on"
                className="text-blue-500"
              >
                https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en&safe=on
              </a>
            </li>
            <li>
              Pour Firefox :{" "}
              <a
                href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies"
                className="text-blue-500"
              >
                https://support.mozilla.org/fr/kb/activer-desactiver-cookies
              </a>
            </li>
            <li>
              Pour Opera :{" "}
              <a
                href="http://help.opera.com/Windows/10.20/fr/cookies.html"
                className="text-blue-500"
              >
                http://help.opera.com/Windows/10.20/fr/cookies.html
              </a>
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 12 - Droits de propriété intellectuelle
          </h2>
          <p className="text-gray-700">
            Tous les éléments du présent Site appartiennent à l'Editeur ou à un
            tiers mandataire, ou sont utilisés par l'Editeur sur le Site avec
            l'autorisation de leur propriétaire. Toute représentation,
            reproduction ou adaptation des logos, contenus textuels,
            pictographiques ou vidéos, sans que cette énumération ne soit
            limitative, est rigoureusement interdite et s’apparente à de la
            contrefaçon.
          </p>
          <p className="text-gray-700">
            Tout Utilisateur qui se rendrait coupable de contrefaçon serait
            susceptible de voir son accès au site supprimé sans préavis ni
            indemnité et sans que cette exclusion ne puisse lui être
            constitutive d’un dommage, sans réserve d’éventuelles poursuites
            judiciaires ultérieures à son encontre, à l’initiative de l’Editeur
            du présent Site ou de son mandataire.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 13 - Responsabilité
          </h2>
          <p className="text-gray-700">
            L'Editeur n’est pas responsable des publications des Utilisateurs,
            de leur contenu ainsi que de leur véracité. L'Editeur ne peut en
            aucun cas être tenu responsable de tout dommage susceptible
            d'intervenir sur le système informatique de l’Utilisateur et/ou de
            la perte de données résultant de l'utilisation du Site par
            l’Utilisateur.
          </p>
          <p className="text-gray-700">
            L'Editeur s'engage à constamment mettre à jour le contenu du Site et
            à fournir aux Utilisateurs des informations justes, claires,
            précises et réactualisées. Le Site est en principe accessible en
            permanence, sauf pendant les opérations techniques de maintenance et
            de mise à jour du contenu.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 14 - Notifications et réclamations
          </h2>
          <p className="text-gray-700">
            Toute notification ou avis concernant les présentes CGU, les
            mentions légales ou la charte de données personnelles doit être
            faite par écrit et envoyée par courrier recommandé ou certifié, ou
            par mail à l'adresse indiquée dans les mentions légales du Site, en
            précisant les coordonnées, nom et prénom du notifiant, ainsi que
            l'objet de l’avis.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-bold text-dark mb-4">
            Article 15 - Loi applicable
          </h2>
          <p className="text-gray-700">
            Les présentes CGU sont régies par et soumises au droit Sénégalaise.
            Sauf dispositions d’ordre public, tous litiges qui pourraient
            survenir dans le cadre de l’exécution des présentes CGU pourront
            avant toute action judiciaire être soumis à l’appréciation de
            l'Editeur du Site en vue d’un règlement amiable.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CGV;
