import React from "react";
import { HappyLogo, PositionIcon, ShopLogo } from "../../../assets";
import { useAddress } from "../../../contexts/localisation";
import { useCart } from "../../../contexts/cart";
import Cart from "../../cart";
import { Link } from "react-router-dom";

interface NavbarProps {
	changed: boolean;
}
export const Navbar: React.FC<NavbarProps> = ({ changed }) => {
	const { address, setOpenCart, openCart } = useAddress();
	const { getTotalQuantity } = useCart();

	const totalQuantity = getTotalQuantity();

	return (
		<>
			{changed ? (
				<nav className="top-0 flex flex-row lg:flex-row mx-auto h-[6rem] lg:h-[7rem] w-full justify-between px-4 lg:px-16 bg-[#EA542B]">
					<div className="w-1/4 lg:w-auto flex items-center justify-start font-bold cursor-pointer">
						<Link to="/">
							<HappyLogo
								className="w-80 h-20 lg:w-[350px] lg:h-[79px]"
								width="100%"
								color={"#FFFFFF"}
							/>
						</Link>
					</div>
					{changed && (
						<div className="w-1/2 lg:w-2/4 pl-[2rem] lg:pl-[0px] flex items-center relative">
							<PositionIcon
								width={"37"}
								height={"37"}
								color={"#FFFFFF"}
							/>
							<span className="w-[64%] lg:w-[37%] text-sm lg:text-base text-white  truncate">
								{address}
							</span>
						</div>
					)}
					<div className="w-1/4 lg:w-auto h-full flex justify-end items-center gap-3 relative">
						{totalQuantity > 0 && (
							<span className="animate-bounce bg-[#FF0000] px-1 py-0.5 text-2xl w-6 h-6 text-white rounded-full flex items-center justify-center absolute top-[1.8rem] right-[1.3rem]">
								{totalQuantity}
							</span>
						)}
						<button
							onClick={() => setOpenCart(true)}
							className="flex items-center justify-end"
						>
							<ShopLogo
								className="w-12 h-10 lg:w-[47px] lg:h-[38px]"
								color={"#FFFFFF"}
							/>
						</button>
					</div>
				</nav>
			) : (
				<nav className="top-0 flex flex-row lg:flex-row mx-auto h-[6rem] lg:h-[7rem] w-full justify-between px-4 lg:px-16 ">
					<div className="w-1/4 lg:w-auto flex items-center justify-start font-bold cursor-pointer">
						<a href="/">
							<HappyLogo
								className="w-80 h-20 lg:w-[350px] lg:h-[79px]"
								width="100%"
								color={"#EA542B"}
							/>
						</a>
					</div>
					<div className="w-1/4 lg:w-auto h-full flex justify-end items-center gap-3 relative">
						{totalQuantity > 0 && (
							<span className="animate-bounce bg-[#FF0000] px-1 py-0.5 text-2xl w-6 h-6 text-white rounded-full flex items-center justify-center absolute top-[1.8rem] right-[1.3rem]">
								{totalQuantity}
							</span>
						)}
						<button
							onClick={() => setOpenCart(true)}
							className="flex items-center justify-end"
						>
							<ShopLogo
								className="w-4 h-10 lg:w-[77px] lg:h-[68px]"
								color={"#EA542B"}
							/>
						</button>
					</div>
				</nav>
			)}
			{openCart && <Cart />}
		</>
	);
};
