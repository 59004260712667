export enum HubPaths {
	Home = "/",
	Category = "/categories",
	Product = "/products/:name",
	ProductDetails = "/products/:name/:id",
	tracking = "/suivi-commande",
	cgv = "/conditions-generales",
	paiement = "/paiements",
	livraison = "/livraisons",
	security = "/securites",
}
