import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SmileIcon } from "../../../assets";
import { useEffect } from "react";

interface Slide {
  id: number;
  imageUrl: string;
  caption: string;
}

interface SliderProps {
  slides: Slide[];
}

export const AutoSlide: React.FC<SliderProps> = ({ slides }) => {
  const settings = {
    showArrows: false,
    showThumbs: false,
    infiniteLoop: true,
    autoPlay: true,
    interval: 3000,
  };
  useEffect(() => {
    const statusElements = document.querySelectorAll(".carousel-status");
    statusElements.forEach((element) => {
      element.remove();
    });
  }, []);

  useEffect(() => {
    const carouselDots = document.querySelectorAll(".dot");

    // Appliquer les styles à chaque dot
    carouselDots.forEach((dot: any) => {
      dot.style.width = "48px";
      dot.style.height = "5px";
      dot.style.background = "#fff";
      dot.style.borderRadius = "10%";
    });

    const activeDot: any = document.querySelector(".dot.selected");
    if (activeDot) {
      activeDot.style.background = "#EA542B";
    }
  }, []);

  return (
    <Carousel
      {...settings}
      className="w-[100%] lg:w-[6s0%] box-border border-1 shadow-2xl rounded-lg"
    >
      {slides.map((slide) => (
        <div key={slide.id} className="p-4 flex" style={{ width: "100%" }}>
          <div className="lg:w-[60%] flex items-center">
            <p className="caption flex flex-col font-bold p-2 text-base lg:text-2xl text-customOrange">
              <span>Livraison instantanée</span>
              <span>Partout a Dakar</span>
              <span className="self-center">
                <SmileIcon width={"100"} height={"31"} color={"#EA542B"} />
              </span>
            </p>
          </div>
          <div className="lg:w-[40%] h-[13rem]">
            <img
              src={slide.imageUrl}
              alt={slide.caption}
              className="w-full h-auto"
            />
          </div>
        </div>
      ))}
    </Carousel>
  );
};
