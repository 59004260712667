const Security = () => {
	return (
		<div className="bg-white text-left container mx-auto p-8">
			<h1 className="text-2xl font-semibold text-dark mb-6">
				Sécurités
			</h1>

			<section className="mb-8">
				<h2 className="text-lg font-bold text-dark mb-4">
					Article 1 - Données collectées sur le Site
				</h2>
				<p className="mb-4 text-gray-700">
					Les Données collectées et ultérieurement traitées
					par le Site sont celles que vous nous transmettez
					volontairement en remplissant les différents
					formulaires présents au sein du Site. Pour
					certaines opérations sur les contenus, vous
					pourrez être amenés à transmettre des Données vous
					concernant à des tiers partenaires au travers de
					leurs propres services, plus spécifiquement lors
					des paiements que vous pourrez effectuer. Nous ne
					disposerons pas desdites données, leur collecte et
					leur traitement étant régis par les conditions
					propres à ces intervenants. Nous vous invitons à
					consulter leurs conditions avant de communiquer
					vos Données dans ce cadre.Votre adresse IP (numéro
					d'identification attribué sur Internet à votre
					ordinateur) est collectée automatiquement. Vous
					êtes informés que le service est susceptible de
					mettre en œuvre un procédé automatique de traçage
					(Cookie), auquel vous pouvez faire obstacle en
					modifiant les paramètres concernés de votre
					navigateur internet, comme expliqué dans les
					conditions générales du présent Site. Les
					coordonnées des Utilisateurs du Site qui se seront
					inscrits sur celui-ci seront sauvegardées, dans le
					respect des dispositions de la loi informatique et
					liberté du 6 janvier 1978. Conformément à cette
					dernière, ils disposent d’un droit d’accès, de
					retrait, de modification ou de rectification des
					Données qu’ils ont fournies. Pour cela, il leur
					suffit d’en faire la demande à l’adresse
					électronique suivante : +221776761887, ou par mail
					contact@happychiill.com.La collecte des Données
					personnelles des Utilisateurs par l'Editeur est en
					cours de déclaration auprès de l’autorité
					française de protection des Données personnelles
					(la Commission Nationale de l’Informatique et des
					Libertés – CNIL).
				</p>
			</section>

			<section className="mb-8">
				<h2 className="text-lg font-bold text-dark mb-4">
					Article 2 - Finalités des Données collectées
				</h2>
				<p className="mb-4 text-gray-700">
					Les Données identifiées comme étant obligatoires
					sur les formulaires du Site sont nécessaires afin
					de pouvoir bénéficier des fonctionnalités
					correspondantes du Site, et plus spécifiquement
					des opérations sur les contenus proposés au sein
					de celui-ci.
					<br />
					Le Site collecte et traite les Données de ses
					Utilisateurs pour envoyer des mails, effectuer des
					statistiques de trafic et traiter les commandes
					sur le Site.
				</p>
			</section>
			<section>
				<h2 className="text-lg font-bold text-dark mb-4">
					Article 3 - Destinataires et utilisation des
					Données collectées
				</h2>
				<p className="mb-4 text-gray-700">
					Les Données collectées par nos soins sont traitées
					pour les besoins d’exécution des opérations sur
					les contenus du Service.Vous êtes susceptible de
					recevoir des courriers électroniques de notre
					Service, notamment dans le cadre de newsletters
					que vous avez acceptées. Vous pouvez demander à ne
					plus recevoir ces courriers électroniques en nous
					contactant à l'adresse info@happychiill.com ou sur
					le lien prévu à cet effet dans chacun des
					courriers électroniques qui vous seront
					adressés.Pour certaines Données uniquement,
					identifiées au sein du formulaire correspondant,
					et sous réserve de votre acceptation volontaire et
					active au travers dudit formulaire, vous acceptez
					que vos Données soient transmises à nos
					partenaires commerciaux à des fins de marketing ou
					publicitaires. Nous vous informons qu’une telle
					transmission à des tiers exclut toutes les Données
					bancaires vous concernant. <br />
					Ces partenaires commerciaux peuvent vous envoyer
					par courrier électronique des informations dans le
					cadre d'opérations promotionnelles précises et
					ponctuelles. Ces partenaires sont spécialement
					choisis par{" "}
					<a
						href="https://happychiill.com"
						className="text-blue-500 underline mr-1"
					>
						https://happychiill.com
					</a>
					en fonction de la qualité de leurs produits et
					services. Vous pouvez demander à ne plus recevoir
					ces courriers électroniques à tout moment en nous
					contactant à l'adresse nfo@happychiill.com ou sur
					le lien prévu à cet effet et inséré en pied de
					page de chacun des courriers électroniques qui
					vous seront adressés.
				</p>
			</section>
			<section>
				<h2 className="text-lg font-bold text-dark mb-4">
					Article 4 - Sécurité des Données
				</h2>
				<p className="mb-4 text-gray-700">
					Vous êtes informés que vos Données pourront être
					divulguées en application d'une loi, d'un
					règlement ou en vertu d'une décision d'une
					autorité réglementaire ou judiciaire compétente ou
					encore, si cela s'avère nécessaire, aux fins, pour
					l'Editeur, de préserver ses droits et intérêts
				</p>
			</section>
			<section>
				<h2 className="text-lg font-bold text-dark mb-4">
					Article 5 - Durée de conservation des Données
				</h2>
				<p className="mb-4 text-gray-700">
					Les Données sont stockées chez l'hébergeur du
					site, dont les coordonnées figurent dans les
					mentions légales du Site, et sont conservées pour
					la durée strictement nécessaire à la réalisation
					des finalités visées ci-avant. Au-delà de cette
					durée, elles seront conservées à des fins
					exclusivement statistiques et ne donneront lieu à
					aucune exploitation, de quelque nature que ce
					soit.Ce document a été mis à jour le 10 Aout 2024.
				</p>
			</section>
		</div>
	);
};

export default Security;
