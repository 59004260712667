import { useEffect, useRef, useState } from "react";
import * as maptilersdk from "@maptiler/sdk";
import "@maptiler/sdk/dist/maptiler-sdk.css";
import "./map.css";
import { TransitionChild } from "@headlessui/react";
import { useCart } from "../contexts/cart";
import deliveryman from "../assets/icons/mask_group.png";
import { useAddress } from "../contexts/localisation";
import { fetchRequest, postRequest } from "../common/request";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { HubPaths } from "../routers/paths";

interface TrackProps {}

const Track: React.FC<TrackProps> = () => {
	maptilersdk.config.apiKey = "NiilP0fg65pIvKx2rjlU";
	const mapContainer = useRef(null);
	const map = useRef<maptilersdk.Map | null>(null);
	const { orderInfo, clearCart } = useCart();
	const { setOpenCart, coordinates } = useAddress();
	const [zoom] = useState(14);
	const navigate = useNavigate();

	// const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

	// useEffect(() => {
	//   const geocodeAddress = async () => {
	//     try {
	//       const response = await axios.get(
	//         `https://api.maptiler.com/geocoding/${encodeURIComponent(
	//           address
	//         )}.json?key=NiilP0fg65pIvKx2rjlU`
	//       );
	//       const { coordinates } = response.data.features[0].geometry;
	//       setCoordinates({ lat: coordinates[1], lng: coordinates[0] });
	//     } catch (error) {
	//       console.error(error);
	//     }
	//   };

	//   geocodeAddress();
	// }, []);
	// console.log(coordinates);

	useEffect(() => {
		if (!map.current && mapContainer.current) {
			map.current = new maptilersdk.Map({
				container: mapContainer.current,
				style: maptilersdk.MapStyle.STREETS,
				center: [coordinates.longitude, coordinates.latitude],
				zoom, // niveau de zoom de départ
			});

			new maptilersdk.Marker({ color: "#FF0000" })
				.setLngLat([139.7525, 35.6846])
				.addTo(map.current);
		}
	}, [coordinates.longitude, coordinates.latitude, zoom]);

	const showInput = () => {
		console.log("object");
	};

	const createOrder = async (userId: number) => {
		console.log("object");

		const data = {
			userId: userId,
			totalAmount: orderInfo?.totalPrice,
			deliveryAddress: orderInfo?.address,
			items: orderInfo?.validatedProducts.map((product) => ({
				productId: product.id,
				quantity: product.quantity,
				price: product.price,
			})),
		};

		const response = await postRequest("order", data);
		console.log("Order created:", response);
		if (response.data.id) {
			clearCart();
			toast.success(
				"Votre commande a été prise en compte avec succès !"
			);
			navigate(HubPaths.tracking);
		}
		setOpenCart(false);
	};

	const handleSubmit = async () => {
		// console.log("Yessss !");
		try {
			const res = await fetchRequest(
				`user/phone/${orderInfo?.phoneNumber}`
			);
			if (res?.data?.id) {
				createOrder(res?.data?.id);
			}
		} catch (error: any) {
			console.error("Error submitting order:", error);
			if (error?.response?.data?.message === "user not found") {
				// console.log("entered");
				const user = await postRequest("user", {
					firstName: orderInfo?.name,
					phoneNumber: orderInfo?.phoneNumber,
					password: "magicPass",
				});
				console.log(user);
				if (user?.data?.id) {
					createOrder(user?.data?.id);
				}
			}
		}
	};

	return (
		<>
			<TransitionChild
				enter="ease-out duration-300"
				enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				enterTo="opacity-100 translate-y-0 sm:scale-100"
				leave="ease-in duration-200"
				leaveFrom="opacity-100 translate-y-0 sm:scale-100"
				leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
			>
				<div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl mx-2">
					<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 hidden lg:block">
						<div className="sm:flex sm:items-start">
							<div className="mt-3 text-center  sm:mt-0 sm:text-left w-full">
								<div className="map-wrap w-full h-[25rem] sm:h-0">
									<div
										ref={mapContainer}
										className="map"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-white  px-4 py-3  sm:px-6 grid grid-cols-1 lg:grid-cols-2 gap-5">
						<div className="bg-customOrange flex flex-col rounded-[15px] p-8 items-center text-center">
							<span className="text-white lg:text-2xl text-xl font-semibold ">
								La commande fait
							</span>
							<span className="text-white lg:text-2xl text-xl font-semibold py-4">
								{orderInfo?.totalPrice} F
							</span>
							<span className="text-white lg:text-xl text-base font-semibold">
								{orderInfo?.name}
							</span>
							<span className="text-white lg:text-xl text-base font-semibold">
								{orderInfo?.phoneNumber}
							</span>
							<button className="bg-white text-customOrange text-base px-2 mt-8">
								Revient au categories
							</button>
							<span className="text-white lg:text-xl text-base font-semibold pt-2">
								Tu peux modifie ta position en
								un seul clic
								<span
									className="animate-pulse px-2"
									onClick={showInput}
								>
									ici
								</span>
							</span>
						</div>
						<div className="flex flex-col items-center">
							<span className="text-customOrange text-xl lg:text-4xl font-semibold mt-3">
								Happy Livraison
							</span>
							<span className="mt-4 mb-4 rounded-full h-24 w-24 flex items-center justify-center border-8 border-customOrange">
								<img
									src={deliveryman}
									alt="delivery man"
								/>
							</span>
							<span className="text-customOrange text-base sm:text-xl font-semibold">
								Par Moussa Gueye 78 745 87 90
							</span>
							<button
								onClick={handleSubmit}
								className="bg-customOrange text-white text-lg lg:text-2xl font-semibold px-4 py-2 w-full mt-4 "
							>
								Confirmer
							</button>
						</div>
					</div>
				</div>
			</TransitionChild>
		</>
	);
};
export default Track;
