import React, { createContext, useContext, useState, ReactNode } from "react";
import { Category } from "../app/company/card/CategoriesList";

interface Address {
  latitude: number;
  longitude: number;
}
interface AddressContextProps {
  address: string;
  category?: Category;
  display: boolean;
  isOpenConsentModal: boolean;
  openCart: boolean;
  coordinates: Address;
  setIsOpenConsentModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  setOpenCart: React.Dispatch<React.SetStateAction<boolean>>;
  setCategory: React.Dispatch<React.SetStateAction<Category | undefined>>;
  setCoordinates: React.Dispatch<React.SetStateAction<Address>>;
}

const AddressContext = createContext<AddressContextProps | undefined>(
  undefined
);

export const AddressProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState<Address>({
    latitude: 14.745149716507733,
    longitude: -17.500200158165107,
  });
  const [category, setCategory] = useState<Category>();
  const [display, setDisplay] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [isOpenConsentModal, setIsOpenConsentModal] = useState(false);

  return (
    <AddressContext.Provider
      value={{
        category,
        setCategory,
        openCart,
        setOpenCart,
        address,
        display,
        isOpenConsentModal,
        setAddress,
        setDisplay,
        setIsOpenConsentModal,
        coordinates,
        setCoordinates,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};

export const useAddress = (): AddressContextProps => {
  const context = useContext(AddressContext);
  if (!context) {
    throw new Error("useAddress must be used within an AddressProvider");
  }
  return context;
};
