import { AutoSlide } from "./company/slider";
import Bear from "../assets/icons/chateau-amandine 1.png";
import { useAddress } from "../contexts/localisation";
import ValidateAdult from "./company/dialog";
import { useEffect, useState } from "react";
import { fetchRequest } from "../common/request";
import CategoriesList from "./company/card/CategoriesList";

interface Data {
	isLoading: boolean;
	categories: any[];
}

const Category = () => {
	const { isOpenConsentModal } = useAddress();
	const [data, setData] = useState<Data>({
		isLoading: false,
		categories: [],
	});

	useEffect(() => {
		setData({ isLoading: true, categories: [] });
		const fethCategories = async () => {
			try {
				const response = await fetchRequest("category");
				console.log(response.data);
				setData({
					isLoading: false,
					categories: response.data,
				});
			} catch (e) {
				console.error(e);
				setData({ isLoading: false, categories: [] });
			}
		};
		fethCategories();
	}, []);

	const slides = [
		{ id: 1, imageUrl: Bear, caption: "bear" },
		{ id: 2, imageUrl: Bear, caption: "Caption 2" },
		{ id: 3, imageUrl: Bear, caption: "Caption 3" },
	];

	return (
		<div className="w-full flex flex-col justify-center items-center gap-5 px-3 pt-[2rem] py-8">
			<AutoSlide slides={slides} />
			{isOpenConsentModal && <ValidateAdult />}
			<div className="grid grid-cols-2 sm:grid-cols-3 gap-8 pt-[2rem]">
				{!data.isLoading ? (
					<CategoriesList {...data} />
				) : (
					<>
						{/* Show skeleton loaders */}
						{[...Array(6)].map((_item, index) => (
							<div
								key={index}
								className="animate-pulse flex-1"
							>
								<div className="bg-slate-200 h-48 min-w-64 flex-1 mb-2"></div>
								<div className="flex-1 space-y-6 py-1">
									<div className="h-8 bg-slate-200 rounded"></div>
								</div>
							</div>
						))}
					</>
				)}
			</div>
		</div>
	);
};

export default Category;
