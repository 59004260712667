import "./App.css";
import { BrowserRouter } from "react-router-dom";
import KRoutes from "./routers";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Toaster position="top-center" />
        <KRoutes />
        {/*  */}
      </BrowserRouter>
    </div>
  );
}

export default App;
