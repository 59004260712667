import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PositionIcon } from "../assets";
import { useAddress } from "../contexts/localisation";
// import { coordinates } from "@maptiler/sdk";

const Home = () => {
  const navigate = useNavigate();
  const { address, setAddress, setCoordinates, coordinates } = useAddress();

  useEffect(() => {
    if (address === "") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.error(
          "La géolocalisation n'est pas prise en charge par ce navigateur."
        );
      }
    }
  }, [address]);

  const showPosition = async (position: GeolocationPosition) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}&addressdetails=1`
      );
      const data = await response.json();
      console.log(data);
      setAddress(data.display_name);
      setCoordinates({ latitude: data.lat, longitude: data.lon });
    } catch (error) {
      console.error(error);
    }
  };

  const goToCategory = () => {
    if (address) {
      navigate("/categories");
    }
  };

  return (
    <div
      className="w-full flex flex-col justify-start items-center gap-5 lg:px-3 lg:pt-[5rem] h-full"
      style={{
        background: "linear-gradient(to bottom, #fff 21.5%, #ea542b 100%)",
      }}
    >
      <div className="w-full flex justify-center gap-3 py-2 rounded-md font-montserrat text-2xl md:text-3xl lg:text-4xl font-bold text-left text-black items-center tracking-wide">
        Livraison,{" "}
        <span className="font-medium text-[#EA542B]"> instantanée</span>
      </div>
      <div className="w-full flex justify-center gap-3 rounded-md font-montserrat text-2xl md:text-3xl lg:text-4xl font-bold text-left text-black items-center tracking-wide">
        Partout a Dakar
      </div>

      <div className="lg:w-3/4 flex flex-col lg:flex-row items-center absolute top-[22rem] lg:top-[30rem]">
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="placeholder:text-center text-left pl-10 focus:outline-none w-full -mt-[25px] lg:mt-0 lg:w-[87%] py-4 lg:py-[25px] placeholder-black rounded-lg bg-neutral-50 text-base lg:text-xl font-extralight"
          placeholder="Tapez votre adresse de livraison"
          autoComplete="off"
        />
        <span className="absolute left-3 lg:top-1/2 transform -translate-y-1/2 w-4 h-4 lg:w-5 lg:h-5 cursor-pointer">
          <PositionIcon width={"27"} height={"27"} color={"#EA542B"} />
        </span>
        <button
          onClick={goToCategory}
          className="flex flex-row justify-center items-center gap-2 mt-[25px] lg:mt-0 lg:ml-4 py-4 lg:py-[25px] w-full lg:w-[45%] px-[30px] rounded-md bg-[#EA542B] flex-grow-0 font-montserrat text-18 font-bold text-white"
        >
          Voir les Categories
        </button>
      </div>
    </div>
  );
};

export default Home;
